.fast-toast {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 8px 17px rgb(0 0 0 / 10%);
    display: inline-flex;
    overflow: hidden;
    padding: 8px;
}

.fast-toast::before {
    content: '';
    background-color: #FA4094;
    border-radius: 5px;
    height: auto;
    width: 3px;
}

.fast-toast > .iziToast-body {
    align-items: center;
    color: #222222;
    display: flex;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    padding: 6px !important;
    margin: auto;
}

.fast-toast > .iziToast-body .iziToast-icon {
    color: #FA4094;
    display: flex;
    align-items: center;
    position: static;
    margin: auto auto auto 16px;
}

.fast-toast > .iziToast-body .iziToast-icon > svg {
    stroke-width: 2;
    height: 24px;
    width: 24px;
}

.fast-toast > .iziToast-body .iziToast-texts {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;
    margin: 0 0 0 16px;
}

.fast-toast > .iziToast-body .iziToast-title {
    padding-bottom: 3px;
    font-weight: bold;
}

.fast-toast > .iziToast-body .iziToast-message {
    margin: 0px;
}

.fast-toast > .iziToast-close {
    background: transparent;
    display: flex;
    margin: auto 20px;
    position: static;
    padding: 0px;
    width: auto;
}

.fast-toast.iziToast-color-info > .iziToast-body .iziToast-icon {
    color: #0065FF;
}

.fast-toast.iziToast-color-info::before {
    background-color: #0065FF;
}

.fast-toast.iziToast-color-success > .iziToast-body .iziToast-icon {
    color: #0A9A6F;
}

.fast-toast.iziToast-color-success::before {
    background-color: #0A9A6F;
}

.fast-toast.iziToast-color-error > .iziToast-body .iziToast-icon {
    color: #DC1818;
}

.fast-toast.iziToast-color-error::before {
    background-color: #DC1818;
}

.fast-toast.iziToast-color-warning > .iziToast-body .iziToast-icon {
    color: #EAB308;
}

.fast-toast.iziToast-color-warning::before {
    background-color: #EAB308;
}

